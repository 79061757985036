import React from 'react';
import capitalize from 'lodash/capitalize';
import * as Ink from '@carta/ink';
import * as JsSearch from 'js-search';
import { graphql } from 'gatsby';
import chunk from 'lodash/chunk';
import styled from 'styled-components';

import { COMPONENT_CATEGORIES } from '../constants';

import Layout from '../components/Layout';
import Header from '../components/Header/Header';
import ComponentGrid from '../components/VisualIndex/ComponentGrid';

const ComponentGridWrapper = styled.div`
  height: 100vh;
`;

const Components = ({
  data: {
    allComponents: { nodes },
  },
}) => {
  const [sortOption, setSortOption] = React.useState('all');
  const [search, setSearch] = React.useState(null);
  const [query, setQuery] = React.useState('');
  const [results, setResults] = React.useState([]);

  const categories = COMPONENT_CATEGORIES.map(cat => {
    return {
      id: cat,
      value: cat,
      label: capitalize(cat),
    };
  });

  const sortOptions = [{ id: 'all', value: 'all', label: 'All' }].concat(categories);

  /*
    react-virtualized expects an array of arrays with two items each, not a flat array. So, we
    need to change the shape of the search results from:
    [
      {displayName: Agreement}, {displayName: Alert}, {displayName: Anchor}, {displayName: Autograph},
    ]

    To:
    [
      [{displayName: Agreement}, {displayName: Alert}],
      [{displayName: Anchor}, {displayName: Autograph}],
    ]
  */
  const makeGrid = components => chunk(components, 2);

  const getComponentsGrid = () => {
    const componentsByCategory = [];
    nodes.map(cmp => {
      if (sortOption === 'all' || (cmp.categories && cmp.categories.includes(sortOption))) {
        componentsByCategory.push(cmp);
      } else if (!cmp.categories && sortOption === 'other') {
        componentsByCategory.push(cmp);
      }
    });
    const sortedMatches = componentsByCategory.sort((a, b) => a.displayName.localeCompare(b.displayName));

    // If there is no search query, show all components
    if (query === '') {
      return makeGrid(sortedMatches);
    }

    // Otherwise, only show components that match the search parameters
    const componentsFromSearch = results ? results.map(result => result.displayName) : [];
    return makeGrid(sortedMatches.filter(cmp => componentsFromSearch.includes(cmp.displayName)));
  };

  const rebuildIndex = React.useCallback(() => {
    // Components Search
    const componentsToSearch = new JsSearch.Search('displayName');
    componentsToSearch.tokenizer = new JsSearch.StopWordsTokenizer(componentsToSearch.tokenizer);
    componentsToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
    componentsToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
    componentsToSearch.searchIndex = new JsSearch.TfIdfSearchIndex('displayName');

    // sets the index attribute for the data
    componentsToSearch.addIndex('displayName');
    componentsToSearch.addIndex('aka');
    componentsToSearch.addDocuments(nodes);

    setSearch(componentsToSearch);
  }, [nodes]);

  React.useEffect(() => {
    rebuildIndex();
  }, [rebuildIndex]);

  const components = getComponentsGrid();

  const searchData = v => {
    setQuery(v);
    const componentQueryResult = search !== null ? search.search(v) : [];
    const componentResults = query === '' ? nodes : componentQueryResult;

    setResults(componentResults);
  };

  return (
    <Layout noBox pageTitle="Components">
      <Ink.Box all={['none', 'none', 'xlarge']} top={['xlarge', 'xlarge', 'xlarge']}>
        <Header
          title="Components"
          subtitle="Components are the reusable building blocks of our design system. Each component meets a specific
              interaction or UI need, and has specifically created to work together to create patterns and intuitive
              user experiences."
        />
      </Ink.Box>
      <Ink.Box horizontal={['none', 'none', 'xlarge']} bottom={['medium', 'gutter']}>
        <Ink.HStack align="distributed">
          <Ink.NewInput
            placeholder="Filter"
            value={query}
            onChange={e => {
              searchData(e.target.value);
            }}
          />
          <Ink.HStack align="right">
            <Ink.Dropdown
              trigger={({ onClick }) => (
                <Ink.Dropdown.Trigger onClick={onClick} type="transparent">
                  Filter by category{`${sortOption !== '' ? `: ${capitalize(sortOption)}` : ''}`}
                </Ink.Dropdown.Trigger>
              )}
              align="right"
            >
              {sortOptions.map(option => (
                <Ink.Dropdown.Item
                  key={option.id}
                  render={props => (
                    <Ink.Dropdown.Belt>
                      <Ink.Dropdown.Radio
                        {...props}
                        id={option.id}
                        label={option.label}
                        value={option.value}
                        checked={option.value === sortOption}
                        name="Dropdown-radio-group"
                        onClick={() => setSortOption(option.value)}
                      />
                    </Ink.Dropdown.Belt>
                  )}
                />
              ))}
            </Ink.Dropdown>
          </Ink.HStack>
        </Ink.HStack>
      </Ink.Box>
      <ComponentGridWrapper>
        <ComponentGrid components={components} />
      </ComponentGridWrapper>
    </Layout>
  );
};

export default Components;

export const query = graphql`
  query ComponentsSearchIndexQuery {
    allComponents: allComponentsJson {
      nodes {
        displayName
        purpose
        status
        group
        categories
        aka
        samples {
          environments
          code
        }
      }
    }
  }
`;
