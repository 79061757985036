import React from 'react';
import PreviewCard from '../Common/PreviewCard';

const RenderCard = props => {
  const component = props.component;
  return (
    <PreviewCard
      key={component.displayName}
      title={component.displayName}
      text={component.purpose}
      actionLabel="View details"
      actionHref={`/components/${component.displayName}`}
      previewWithCheckerboard
      previewCode={component.samples && component.samples.length > 0 && component.samples[0].code}
      hasPadding
    />
  );
};

export default RenderCard;
