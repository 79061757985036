import React from 'react';
import styled from 'styled-components';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Grid } from 'react-virtualized';
import * as Ink from '@carta/ink';
import RenderCard from './RenderCard';

const CardsGridWrapper = styled.div`
  padding-left: 32px;
  padding-right: 32px;
  margin-right: -20px;
  margin-bottom: -20px;
  height: 100%;

  @media (max-width: ${Ink.breakpoints['TABLET_PORTRAIT_WIDTH']}px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const ComponentGrid = props => {
  const cache = React.useMemo(
    () =>
      new CellMeasurerCache({
        fixedWidth: true,
      }),
    [],
  );
  /*
    Due to the way react-virtualized creates the Grid, we need to grab each
    component by cell/column index. However, some rows may not have two elements
    (if there are an uneven number of components in the search results), so we need to
    account for the case that this indexing may cause a TypeError.
  */
  const renderGridComponentCards = (cellProps, components) => {
    let component = null;
    try {
      component = components[cellProps.rowIndex][cellProps.columnIndex];
    } catch (TypeError) {
      return;
    }
    if (component) {
      return (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          key={cellProps.key}
          parent={cellProps.parent}
          rowIndex={cellProps.index}
        >
          <div style={cellProps.style}>
            <RenderCard component={component} />
          </div>
        </CellMeasurer>
      );
    }
  };

  return (
    <CardsGridWrapper>
      <AutoSizer>
        {autoProps => {
          return (
            <Grid
              columnCount={2}
              columnWidth={autoProps.width / 2}
              height={autoProps.height}
              rowCount={props.components.length}
              rowHeight={500}
              width={autoProps.width}
              cellRenderer={cellProps => renderGridComponentCards(cellProps, props.components)}
            />
          );
        }}
      </AutoSizer>
    </CardsGridWrapper>
  );
};

export default ComponentGrid;
